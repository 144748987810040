define("discourse/plugins/discourse_chain_topics/discourse/lib/topic-custom-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fieldInputTypes = fieldInputTypes;
  _exports.isDefined = isDefined;
  function isDefined(value) {
    return value !== null && value !== undefined;
  }
  function fieldInputTypes(fieldType) {
    return {
      isBoolean: fieldType === "boolean",
      isString: fieldType === "string",
      isInteger: fieldType === "integer",
      isJson: fieldType === "json"
    };
  }
});